import styled, { css } from "styled-components";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 44px;
  justify-content: space-between;
  width: 100%;
  background-color: var(--color-white);
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.15);
  border-radius: 0px 20px;
  padding: 20px 16px;
  max-width: 570px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding: 40px 56px;

    ${(p) =>
      p.$fluid &&
      css`
        max-width: 100%;
        flex-direction: row-reverse;
        & > div:last-child {
          max-width: 470px;
          flex-shrink: 0;
        }
      `}
  }

  button {
    margin-bottom: 0px;
    margin-top: auto;
  }

  & > div {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      align-items: ${(p) => (p.$fluid ? "flex-start" : "center")};
      text-align: ${(p) => (p.$fluid ? "left" : "center")};
    }
  }

  &:hover {
    button {
      background: ${(p) => p.theme.secondary};
      color: ${(p) => p.theme.primary};
    }

    .taxsheets-animate {
      transform: translateY(-8%);

      .top1 {
        transform: translate(20px, -25px);
      }

      .topExcel {
        transform: translate(-20px, -25px);
      }
    }

    .taxdashs-animate {
      transform: translateY(-8%);

      .topDashs {
        transform: translateY(-35px);
      }
    }
  }

  .taxsheets-animate {
    flex-grow: 1;
    pointer-events: none;
    position: relative;
    transition: all 300ms ease-in-out;

    width: 160%;
    margin-top: -25%;
    margin-bottom: -25%;

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      ${(p) =>
        p.$fluid &&
        css`
          height: 160%;
          margin-left: -5%;
          margin-right: -20%;
          margin-top: -27%;
          margin-bottom: -35%;
        `}
    }

    .base {
      width: 100%;
      position: relative;
      top: 0;
      left: 0;
      transition: all 300ms ease-in-out;
    }

    .top1 {
      position: absolute;
      width: 40%;
      bottom: 41%;
      left: 16%;
      transition: all 300ms ease-in-out;
    }

    .topExcel {
      position: absolute;
      width: 30%;
      bottom: 30%;
      left: 22%;
      transition: all 300ms ease-in-out;
    }
  }

  .taxdashs-animate {
    flex-grow: 1;
    pointer-events: none;
    position: relative;
    transition: all 300ms ease-in-out;

    width: 160%;
    margin-top: -25%;
    margin-bottom: -25%;

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      ${(p) =>
        p.$fluid &&
        css`
          height: 160%;
          margin-left: -5%;
          margin-right: -20%;
          margin-top: -27%;
          margin-bottom: -35%;
        `}
    }

    .baseDashs {
      width: 100%;
      position: relative;
      top: 0;
      left: 0;
      transition: all 300ms ease-in-out;
    }

    .topDashs {
      position: absolute;
      width: 60%;
      bottom: 34%;
      left: 10%;
      transition: all 300ms ease-in-out;
    }
  }

  .logo {
    max-width: 210px;
  }

  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: ${(p) => p.theme.primary};
    margin: 20px 0;
  }

  p {
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    /* or 141% */
    color: ${(p) => p.theme.textLightGray};
    margin-bottom: 24px;

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      margin-bottom: 36px;
    }
  }
`;
