import styled from "styled-components";

export const Apresentacao = styled.section`
  padding: 112px 0;
  background-color: ${(p) => (p.$white ? "white" : p.theme.bgGray)};
  margin-left: auto;
  margin-right: auto;


  h6 {
    font-weight: 600;
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.primary};
    margin-bottom: 32px;

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      max-width: 470px;
      margin-bottom: 40px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
