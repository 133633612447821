import React from "react";
import Button from "../../../DesignSystem/Button";
import Input from "../../../DesignSystem/Input";
import { Container } from "../../../styles/UI";

import { useInput } from "../../../hooks/useInput";

import Download from "../../../icons/download.inline.svg";

import * as S from "./style";
import { navigate } from "gatsby-link";

import axios from "axios";

export default function ApresentacaoHome(props) {
  const {
    value: email,
    isInvalid: isInvalidEmail,
    bind: bindEmail,
  } = useInput("", "email");

  const client = axios.create({
    baseURL: `https://taxcellicenseserver2.azurewebsites.net/`,
    headers: {
      "Content-Type": `application/x-www-form-urlencoded`,
    },
  });

  const baixarApresentacao = () => {
    if (email === "" || isInvalidEmail) {
      alert("Digite um email válido para baixar a apresentação");
      return;
    }

    const form = new URLSearchParams();

    form.append("source", "Apresentação");
    form.append("email", email);
    form.append("empresa", "");
    form.append("celular", "");
    form.append("nome", "");

    client.post(`/RD_Station.aspx`, form).then((resp) => {
      console.log(resp);
    });

    if (typeof window !== "undefined")
      window.location.href = `/apresentacao.pdf`;
  };

  return (
    <S.Apresentacao $white={props.white}>
      <Container>
        <h6>Quer apresentar a Taxcel para sua empresa?</h6>
        <Input big center placeholder="Seu email" {...bindEmail}>
          <Button
            onClick={baixarApresentacao}
            size="big"
            iconColor="secondary"
            iconLeft
          >
            <Download />
            Baixar a apresentação
          </Button>
        </Input>
      </Container>
    </S.Apresentacao>
  );
}
